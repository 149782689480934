<!-- 申请提现 -->
<template>
  <div id="apply">
    <div class="box_shadow apply">
      <div class="flex-container account">
        <span>提现账户</span>
        <p class="flex1" @click="callAccount">
          {{ !bank_list ? "请添加那你的提现账户" : bank_list.memberbank_no }}
        </p>
      </div>
      <div class="data">
        <p class="title">提现金额</p>
        <div class="input">
          <!-- <i>￥</i><input class='money' placeholder="输入提现金额" type="text" name="" value=""> -->
          <i>￥</i>
          <p class="money" @click="keyBoo = true">
            {{ keyVal.length > 0 ? keyVal : "输入提现金额" }}
          </p>
        </div>
        <div class="now flex-container">
          <span class="flex1">可提现金额 ￥{{ allPrice }}</span>
          <b @click="keyVal = allPrice">全部提现</b>
        </div>
      </div>
    </div>

    <div
      v-if="keyVal.length > 0 && bank_list"
      class="btn yes_apply"
      @click="toApply"
    >
      申请提现
    </div>
    <div v-else class="btn no_apply">
      申请提现
    </div>

    <p class="apply_tips">现在申请，预计<i>7</i>个工作日内到账</p>

    <van-number-keyboard
      :show="keyBoo"
      extra-key="."
      theme="custom"
      close-button-text="完成"
      @blur="keyBoo = false"
      v-model="keyVal"
    />

    <van-popup
      @close="callClost"
      v-model="accountBoo"
      round
      position="bottom"
      :overlay="false"
      :style="{ height: '98%' }"
      :closeable="true"
    >
      <div id="account">
        <template v-for="item in dataInput">
          <textInput class="m32">
            <field
              :border="false"
              v-model="item.val"
              :label="item.title"
              :placeholder="item.placeholder"
              class="flex-container"
              maxlength="11"
              rule
              :type="item.type"
            />
          </textInput>
        </template>

        <div class="btn" @click="saveAccount">
          保存使用
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { NumberKeyboard, Toast, Popup, Field, Dialog } from "vant";
import textInput from "@/components/textInput/";
import { post } from "@get/http";
export default {
  name: "",
  data: () => ({
    jiang_yc: localStorage["19jiang_yc"] ? localStorage["19jiang_yc"] : 0,
    accountBoo: false,
    keyBoo: false,
    keyVal: "",
    allPrice: "0.00",
    bank_list: false, //账户列表
    dataInput: [
      {
        title: "真实姓名",
        val: "",
        placeholder: "请输入真实姓名",
        type: "text",
      },
      {
        title: "手机号",
        val: "",
        placeholder: "请输入手机号",
        type: "digit",
      },
    ],
    final_Val: false,
  }),
  components: {
    vanNumberKeyboard: NumberKeyboard,
    textInput,
    Toast,
    vanPopup: Popup,
    Field,
  },
  async created() {
    if (this.jiang_yc == 1) {
      this.$router.push({
        name: "HomeIndex",
      });
      return false;
    }
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      loadingType: "spinner",
      overlay: true,
      duration: 0,
    });
    let { member_info } = await post("/Member/index");
    this.allPrice = member_info.available_predeposit;
    this.getAuthStatic();

    this.getAccount();
    Toast.clear();
  },
  methods: {
    async toApply() {
      Toast.loading({
        message: "申请中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      //申请提现
      await post("/v2.Recharge/pd_cash_add", {
        data: {
          memberbank_id: this.bank_list.memberbank_id, //支付账户ID
          pdc_amount: this.keyVal, //提现金额
        },
      });
      Toast.clear();
      Dialog.alert({
        title: "十九匠商城通知",
        message: "申请提现成功",
      }).then(() => {
        this.$router.push({ name: "Money" });
      });
    },
    callClost() {
      // 关闭调用提现账户
      this.accountBoo = false;
      if (this.bank_list) {
        this.dataInput[0].val = this.bank_list.memberbank_truename;
        this.dataInput[1].val = this.bank_list.memberbank_no;
      } else {
        this.dataInput[0].val = "";
        this.dataInput[1].val = "";
      }
    },
    callAccount() {
      // 调用提现账户
      this.accountBoo = true;
    },
    async getAuthStatic() {
      //获取实名认证状态
      let result = await post("/v2.Member/getAuth");
      if (result.member_auth_state == 0) {
        // 未实名
        Dialog.confirm({
          title: "十九匠商城通知",
          message: "需要实名认证才可以申请提现哦~",
        })
          .then(() => {
            // on confirm
            this.$router.push({
              name: "Real",
            });
          })
          .catch(() => {
            // on cancel
            this.$router.go(-1);
          });
        return false;
      } else if (result.member_auth_state == 2) {
        // 未通过
        Dialog.confirm({
          title: "十九匠商城通知",
          message: "您的实名认证被驳回了！请重新申请！",
        })
          .then(() => {
            this.$router.push({ name: "Real" });
          })
          .catch(() => {
            this.$router.go(-1);
          });
        return false;
      }
    },
    async getAccount() {
      // 获取账户
      let { bank_list } = await post("/Memberbank/bank_list");
      let data = bank_list;
      if (data[0]?.memberbank_id == -1) {
        return false;
      }
      if (data.length > 0) {
        this.bank_list = data[0];
        this.dataInput[0].val = this.bank_list.memberbank_truename;
        this.dataInput[1].val = this.bank_list.memberbank_no;
      }
    },
    // isReal(){
    //   // 检查是否实名认证
    //   requestApi('/Member/auth','POST',{},'member',false,false).then(res=>{
    //
    //   })
    // },
    async saveAccount() {
      // 保存账户
      Toast.loading({
        message: "提交中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      if (this.bank_list) {
        // 有账户 进行编辑处理
        await post("/memberbank/bank_edit", {
          data: {
            memberbank_id: this.bank_list.memberbank_id,
            memberbank_type: "weixin",
            memberbank_truename: this.dataInput[0].val,
            memberbank_name: "",
            memberbank_no: this.dataInput[1].val,
          },
        });
        Toast("十九匠商城:已保存");
        this.accountBoo = false;
        this.bank_list.memberbank_truename = this.dataInput[0].val;
        this.bank_list.memberbank_no = this.dataInput[1].val;
      } else {
        //无账户  进行添加处理
        let { memberbank_id } = await post("/memberbank/bank_add", {
          data: {
            memberbank_type: "weixin",
            memberbank_truename: this.dataInput[0].val,
            memberbank_name: "",
            memberbank_no: this.dataInput[1].val,
          },
        });
        this.bank_list = {
          memberbank_id: memberbank_id,
          memberbank_type: "weixin",
          memberbank_truename: this.dataInput[0].val,
          memberbank_name: "",
          memberbank_no: this.dataInput[1].val,
        };
        Toast("十九匠商城:已添加");
        this.accountBoo = false;
        this.bank_list.memberbank_truename = this.dataInput[0].val;
        this.bank_list.memberbank_no = this.dataInput[1].val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#apply {
  background: #ededed;
  height: 100%;
  padding-top: rem(54);
}
.m32 {
  margin: 0 rem(32);
}
.apply {
  padding: 0 rem(32);
  box-sizing: border-box;
  .account {
    height: rem(90);
    font-size: rem(28);

    align-items: center;

    span {
      color: #808080;
    }
    p {
      background: url("~@image/me/icon_more.png") no-repeat right center;
      background-size: rem(12) rem(22);
      color: #343434;
      text-align: right;
      padding-right: rem(24);
    }
  }
}

.data {
  font-weight: 500;
  .title {
    color: #343434;
    font-size: rem(28);
    padding: rem(28) 0;
  }
  .input {
    border-bottom: rem(1) solid #ebebeb;
    i {
      font-size: rem(36);
      font-weight: bold;
      color: #343434;
    }
    .money {
      display: inline-block;
      // color: #343434;
      font-size: rem(36);

      border: none;
      margin-left: rem(28);
      padding: rem(32) 0 rem(58);
      color: #b3b3b3;
      width: rem(500);
    }
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #b3b3b3;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #b3b3b3;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #b3b3b3;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b3b3b3;
    }
  }
  .now {
    font-size: rem(22);
    padding: rem(36) 0;
    color: #b3b3b3;
    b {
      text-align: right;
    }
  }
}

.btn {
  margin: rem(192) auto 0;
  width: rem(710);
  height: rem(88);
  border-radius: rem(8);
  font-size: rem(32);
  font-weight: bold;
  text-align: center;
  line-height: rem(88);
  color: #fff;
}
.no_apply {
  background: #b3b3b3;
}
.yes_apply {
  background: #ff4848;
}
.apply_tips {
  margin-top: rem(28);
  text-align: center;
  font-size: rem(22);

  color: #808080;
  i {
    color: #333333;
    font-weight: bold;
  }
}

#account {
  height: 100%;
  // padding-top: rem(56);
  background: #fff;
  .btn {
    position: fixed;
    bottom: rem(32);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: rem(640);
    height: rem(88);
    background: #ffe5e5;
    border-radius: rem(44);
    font-size: rem(32);
    font-weight: bold;
    color: #ff4747;
    text-align: center;
    line-height: rem(88);
  }
}
</style>
