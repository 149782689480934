<template>
  <div id="inpuText" class="input-text flex-container ">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: "",
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
<style lang="scss">
@import "~@css/mixin.scss";
#inpuText {
  background: #fff;
}

.input-text {
  height: rem(120);

  border-bottom: rem(1) solid #ebebeb;

  box-sizing: border-box;
  align-items: center;
  .van-cell {
    padding: 0;
  }
  .van-cell__title,
  .__title {
    width: rem(154);
    font-size: rem(26);
    font-weight: 500;
    color: #7f7f7f;
    margin: 0;

    // margin-right: rem(56);
  }
  .van-field__control,
  p,
  b {
    font-size: rem(28);
    color: #000;
    border: none;
    font-weight: bold;
  }
  b {
    color: #b2b2b2;
    // text-align: right;
    display: block;
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #b2b2b2;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #b2b2b2;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #b2b2b2;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b2b2b2;
  }
}
</style>
